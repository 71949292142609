@import './constants.scss';

@media only screen and (max-width: 360px) {
  .button-container {
    margin: 0;
    button,
    a {
      font-size: 0.8rem;
    }
  }
  .todo-add {
    section {
      height: 50px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .button-container {
    max-width: 100%;
  }

  .authenticator {
    background: none;
    #forgot-password-text {
      margin-top: 0;
      text-decoration: underline;
    }
    .sign-in-auth,
    .signup-auth {
      max-width: 100%;
      margin-top: 0;
      box-shadow: none;
      .hand-font {
        margin-top: 0;
      }
      .signup-input-container {
        .signup-input-container-privacy {
          margin-top: 0.3rem;
        }
      }
    }
    .forgot-password-auth {
      margin-top: 100px;
      .forgot-password-container {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }
    }
  }

  .dash {
    #dash-section {
      flex-direction: column;
      .hours-summary {
        width: 100%;
        margin: 0;
        text-align: left;
        height: auto;
        p {
          margin-bottom: 0.5rem;
          color: $medium-grey;
          text-transform: uppercase;
        }
      }
      .todo-hub {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
  }

  .todo-hub {
    padding: 0;
    background-color: $white;
  }

  .profile,
  .profile-edit-form {
    align-items: initial;
    .profile-input-blocks {
      width: 100%;
    }
  }

  .hours-add {
    width: 100%;
    .active-subscription {
      margin-top: auto;
      p {
        color: $gold;
        max-width: 250px;
        margin: 0 auto 0 auto;
      }
      .a-la-carte {
        p {
          color: unset;
        }
      }
    }
    .modify-subscription {
      p {
        color: $black;
      }
    }
    .monthly-subs,
    .a-la-carte {
      margin-top: 25px;
    }
    .Form {
      margin-top: 50px;
    }
    .hours-form {
      i {
        margin-left: 1.5rem;
        font-size: 1.1rem;
      }
    }
  }

  .todo-add {
    max-width: 100%;
    section {
      height: 65px;
    }
    .save-notice {
      font-size: 10px;
      margin: 10px;
    }
    .todo-success {
      margin-top: 50px;
      img {
        width: 100px;
      }
      p {
        font-size: 1rem;
      }
    }
  }

  .ui-message {
    width: auto;
  }

  .button-container {
    flex-direction: column;
    justify-content: initial;
    a,
    button {
      margin-left: auto;
      margin-right: auto;
    }

    p.save-notice {
      padding: 0px 1.5rem 0px 1.5rem;
      font-size: 10px;
      color: $medium-grey;
    }
  }

  .grid-container {
    div {
      p:nth-child(1) {
        font-size: 2rem;
      }
      p:nth-child(2) {
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      p:nth-child(3) {
        font-size: 0.6rem;
        color: $medium-grey;
      }
    }
    div:nth-child(4) {
      p {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
}
