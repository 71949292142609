@import './constants.scss';

.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  z-index: 1;
  background-color: transparent;
  i {
    position: absolute;
    left: 0;
    padding-left: 2rem;
    color: $green;
  }
  i:hover {
    cursor: pointer;
  }
  a {
    position: absolute;
    left: 0;
    padding-left: 2rem;
    top: 2rem;
    color: $green;
  }
  p {
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  span {
    position: absolute;
    top: 2rem;
    right: 70px;
  }
}

.header {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: $header-grey;
  font-family: $montserrat;
  font-size: 1.3rem;
  position: sticky;
  #avatar {
    height: 25px;
    width: 25px;
    margin: 0 70px 0 auto;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    object-fit: cover;
  }
  span {
    position: absolute;
    top: 30px;
    right: 65px;
    background-color: #e85252;
    padding: 2px 7px 2px 7px;
    font-size: 10px;
    border: 1px solid #e85252;
    border-radius: 50%;
    color: white;
  }
  img {
    height: auto;
    width: 150px;
    margin: 0 0 0 1.5rem;
  }
  .hamburger {
    position: absolute;
    right: 0;
    margin-right: 2rem;
    z-index: 10;
    img {
      height: 25px;
      width: auto;
      color: $gold;
      margin-top: 0.4rem;
      z-index: 10;
    }
    img:hover {
      cursor: pointer;
    }
  }
  .nav-links {
    z-index: 5;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    bottom: 0;
    top: 0;
    background: #000;
    clip-path: circle(100px at 90% -20%);
    -webkit-clip-path: circle(100px at 90% -20%);
    transition: all 1.5s ease-out;
    pointer-events: none;
    li {
      list-style: none;
      opacity: 0;
      transition: all 0.2s ease 0.2s;
      color: $gold;
      font-family: $adelle;
      letter-spacing: 3px;
      font-weight: 300;
      a,
      p {
        color: $gold;
        text-decoration: none;
      }
      p:hover {
        cursor: pointer;
      }
      a::after,
      p::after {
        transition: all ease-in-out 0.2s;
        background: none repeat scroll 0 0 $gold;
        content: '';
        display: block;
        height: 2px;
        width: 0;
      }
      a:hover::after,
      p:hover::after {
        width: 100%;
      }
    }
    li.fade {
      opacity: 1;
    }
  }
  .nav-links.open {
    clip-path: circle(2000px at 90% -10%);
    -webkit-clip-path: circle(2000px at 90% -10%);
    pointer-events: all;
  }
}

.ui-message {
  width: 500px;
  padding: 1rem;
  margin-left: auto;
  margin-right: auto;
  color: $medium-grey;
  text-align: center;
  font-size: 1rem;
  color: $medium-grey;
}

.ui-message.hours {
  font-size: 0.75rem;
  margin-top: 0.75rem;
}

h3.general-notice {
  margin: 1em;
  text-align: center;
}

.button-container.create-account {
  flex-direction: column;
  align-items: center;
}

.button-container {
  margin: 1rem auto 0.5rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: $commuter-sans;
  max-width: 70%;

  a,
  button {
    margin: 0.7rem;
    border: none;
    background: none;
    padding: 1rem;
    width: 300px;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    z-index: 1;
    letter-spacing: 3px;
  }

  a:hover,
  button:hover {
    cursor: pointer;
  }

  .bg-black {
    background-color: $black;
    border: 1px solid $green;
    color: $gold;
    font-family: $montserrat;
  }

  .bg-gold {
    background-color: $gold;
    border: 1px solid $gold;
    color: $green;
    font-family: $montserrat;
  }

  .bg-red {
    background-color: white;
    border: 1px solid red;
    color: red;
  }

  .bg-white {
    background-color: white;
    border: 1px solid$gold;
    color: $gold;
  }
}

.form-alert-text {
  position: absolute;
  bottom: 0;
  background-color: $off-white;
  padding: 0.5rem;
  z-index: 5;
  text-transform: uppercase;
  color: $medium-grey;
  width: 100%;
  text-align: center;
}

.loader {
  height: 40px;
  width: 40px;
  margin: 100px auto;
  background-color: #dfc9a6;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
  animation: sk-scaleout 1s infinite ease-in-out;
}
@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

.assistant-display {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  color: $gold;
  padding: 1rem;
}

img.assistant-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.underline {
  text-decoration: underline;
}

p.create-account-message {
  color: black;
  text-align: center;
}
